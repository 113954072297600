import React, { useState, useEffect, memo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import * as CatalogAPI from "shared/src/services/catalogsAPI";
import { fetchWorkshopTypes } from "shared/src/store/catalogSlice";
import {
  getLoading,
  getWorkshopTypes,
  setCurrentSection,
  setData,
  resetData,
} from "../../stores";
import WorkshopCard from "./WorkshopCard/index";
import ConfirmationMessage from "../../app/Components/Modals/ConfirmationMessage";
import { ConfirmationModalData } from "../../app/models/modal";
import { Workshop, WorkshopType } from "shared/src/data-models/Workshop";
import "./style.css";
import { CkAddButton } from "../../CkUI";

interface IProps {
  workshops: Array<Workshop>;
  setWsIndex: Function;
  nextSetting: Function;
  prevSetting: Function;
  setWorkshopDetails: Function;
  setWorkshopToWorkers: Function;
  RemoveWorkshop: Function;
  UpdateWorkshop: Function;
  /* AddWorkshop: Function; */
  isMec: boolean;
  isNewAccount?: boolean;
}

interface IVal {
  id: string;
  name: string;
  email: string;
  sucursalName: string;
}

const MyWorkshopsNEW: React.FC<IProps> = ({
  nextSetting,
  prevSetting,
  workshops,
  setWsIndex,
  setWorkshopDetails,
  setWorkshopToWorkers,
  RemoveWorkshop,
  UpdateWorkshop,
  isMec,
  isNewAccount,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const workshopTypes: WorkshopType[] = useSelector(getWorkshopTypes);
  const loading = useSelector(getLoading);

  const [sasToken, setSasToken] = useState<string>("");
  const [confirmationModal, setConfirmationModal] =
    useState<ConfirmationModalData>({ visible: false, index: -1 });

  const fetchSASToken = async () => {
    const token = await CatalogAPI.fetchSASToken();
    //@ts-ignore
    setSasToken(token.data! || "");
  };

  const fetchDataWorkshopTypes = useCallback(async () => {
    dispatch(fetchWorkshopTypes("ES-MX"));
  }, []);

  const goEditWorkshop = (index: number) => {
    setWorkshopDetails(index);
    setWsIndex(index);
  };

  const goEditWorkers = (index: number) => {
    setWorkshopToWorkers(index);
    setWsIndex(index);
  };

  const removeWorkshop = async (index: number) => {
    await setWsIndex(index);
    setConfirmationModal({ visible: true, index: index });
  };

  const eraseWorkshop = (index: number) => {
    RemoveWorkshop(workshops[index].id);
    setConfirmationModal({ visible: false, index: -1 });
  };

  const duplicateWorkshop = async (index: number) => {
    // await setCloneWorkshop(index.toString());
    // add name, sucursal.
    cloneWorkshopData(workshops[index]);
    history.push("/agregar-nuevo-taller");
  };

  const cloneWorkshopData = (workshopData: any) => {
    dispatch(resetData());
    //const cloneWS = await WorkshopAPI.getById(parseInt(urlParams.id))
    //obtener workshops
    //console.log("toBeCloneWS: ", workshopData);
    const clonedWorkshop = {
      ...workshopData,
      location: {
        zipCode: "",
        city: "",
        municipality: "",
        neighborhood: "",
        address: "",
        latitude: 0, //19.42847,
        longitude: 0,
      },
      id: 0,
      dsrStatusCode: '',
      dsrStatusDesc: '',
      rating: '',
      name: `${workshopData.name} 2`,
      workshopPhotos: [],
      WorkshopIdentificator: workshopData.workshopIdentificator? +workshopData.workshopIdentificator + 1 : "",
      workshopIdentificator: workshopData.workshopIdentificator? +workshopData.workshopIdentificator + 1 : "",
      carTypes: workshopData.carTypes.map((item) => item.code),
      additionalInfo: workshopData.additionalInfo.map((item) => item.code),
    }
    dispatch(setData(clonedWorkshop))
  };

  const updateNotifications = (data: Workshop, notif: boolean) => {
    UpdateWorkshop({ ...data, ownerReceiveNotifications: notif });
  };

  useEffect(() => {
    //setAddingWorkshop(loading);
  }, [loading]);

  useEffect(() => {
    fetchSASToken();
    fetchDataWorkshopTypes();
    dispatch(setCurrentSection("Mis talleres"));
  }, []);

  return (
    <>
      <Helmet>
        <title>CarKer Para Talleres Automotrices</title>‍
        <meta
          name="description"
          content="Digitaliza tu taller automotriz formando parte de la plataforma CarKer y conecta con más clientes."
          data-react-helmet="true"
        />
      </Helmet>
      <section className="page-container my-workshops-container">
        <div className="list-container">
          {workshops.map((workshop, index) => (
            <WorkshopCard
              key={index}
              index={index}
              workshop={workshop}
              workshopTypes={workshopTypes}
              sasToken={sasToken}
              goEditWorkshop={goEditWorkshop}
              goEditWorkers={goEditWorkers}
              removeWorkshop={removeWorkshop}
              duplicateWorkshop={duplicateWorkshop}
              updateNotifications={updateNotifications}
              isMec={isMec}
            />
          ))}
          <CkAddButton
            className="add-workshop-btn"
            onClick={() => {
              //setModalCreate(true)
              dispatch(resetData())
              if (isNewAccount) {
                history.push("/nuevo-taller-nueva-cuenta");
              } else history.push("/agregar-nuevo-taller");
            }}
            id="ACCTSETUP001"
            triggerGTMOnClick
          >
            Agregar otro taller
          </CkAddButton>
        </div>

        <ConfirmationMessage
          title="Eliminar taller"
          message="¿Estás seguro que deseas eliminar este taller?"
          onConfirm={() => eraseWorkshop(confirmationModal.index)}
          onClose={() => {
            setConfirmationModal({ visible: false, index: -1 });
          }}
          visible={confirmationModal.visible}
          type="delete"
        />
      </section>
    </>
  );
};

export default memo(MyWorkshopsNEW);
