import React, { useEffect, memo, useState } from "react";
import { Row } from "antd";
import {CkButton, CkModal} from "../../CkUI";
import ClientList from "./ClientList";
import AddClientDrawer from "./AddClientDrawer";
import "./style.css"
import { ClientsAPI } from "../../app/Services/axios";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentWorkshop, getCustomerFormData, getCustomerListFilterData} from "../../stores";
import ClientCardDrawer from "./ClientCardDrawer";
import {fetchWorkshopTags, resetCustomerFormData} from "../../stores/Slice/NewCustomerFormSlice";

interface IProps {}

const MyCustomersPage: React.FC<IProps> = ({  }) => {
  const dispatch = useDispatch();
  const [openAddClient, setOpenAddClient] = useState(false);
  const [noticeMessage, setNoticeMessage] = useState("");
  const [showNoticeMessage, setShowNoticeMessage] = useState(false);
  const [isEditingCustomer, setIsEditingCustomer] = useState(false);
  const [openClientCard, setOpenClientCard] = useState(false);
  const currentWorkshop: any = useSelector(getCurrentWorkshop);
  const customerData = useSelector(getCustomerFormData);
  const filterData = useSelector(getCustomerListFilterData);
  const [currentWorkshopId, setCurrentWorkshopId] = useState(null);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState({
    customerName: "",
    customerLastName: "",
    customerTel: "",
    customerEmail: "",
    contacts: [],
    customerZipCode: "",
    customerDateOfBirth: "",
    customerSex: "",
    customerAddress: "",
    customerNotes: "",
    customerReceptorName: "",
    customerRFC: "",
    customerTaxRegimen: "",
    customerCFDI: "",
    customerTaxZipCode: "",
    customerTaxEmail: "",
    customerTags: [],
    customerVehicles: [],
    appointmentHistory: [],
    appointments: [],
  })

  useEffect(() => {
    if(searchTerm.length > 0 && clients.length > 0) {
      setFilteredClients(clients.filter(
        (client) => {
          return client.customerName.toLowerCase().includes(searchTerm.toLowerCase()) || client.customerLastName.toLowerCase().includes(searchTerm.toLowerCase())
        }
      ))
    }
  }, [searchTerm, clients]);

  interface Customer {
    customerLastName: string,
    customerName: string,
  }
  const orderCustomerList = ( cList: Customer[] ) => {
    return cList.sort(( function(a, b) {
        const textA = a.customerLastName.toUpperCase();
        const textB = b.customerLastName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      }
    ));
  }

  const addHeaderToData = (dataList) => {
    let result = [];
    if(dataList.length > 0){
      const orderedData = dataList;
      const headerLetters = [];
      orderedData.map(client => {
        const firstLetter = client?.customerLastName[0].toUpperCase();

        if(!headerLetters.includes(firstLetter)) {
          headerLetters.push(firstLetter);
          result.push({
            key: result.length,
            customerName: firstLetter,
            customerTel: "",
            customerVehicles: [ ],
            lastAppointment: { serviceName: "", serviceDate: ""},
            customerTags: [],
            isHeader: true,
          })
        }
        result.push({
          key: result.length,
          ...client
        });

      });
    }
    return result;
  }

  const getClients = async () => {
    console.log("filterData: ", filterData);
    let tagsStr = "";
    filterData.tags.map((tag, index) => {
      tagsStr += `${tag}`;
      if(index+1 < filterData.tags.length){
        tagsStr += "|"
      }
    });
    const tagFilter = tagsStr? tagsStr : null;
    const lastServiceFilter = filterData.lastServiceDate? filterData.lastServiceDate : null;
    const vehicleTypeFilter = filterData.vehicleType? filterData.vehicleType : null;
    const vehicleBrandFilter = filterData.vehicleBrand? filterData.vehicleBrand : null;
    const licenceEndingFilter = filterData.vehicleLicenceTermination? filterData.vehicleLicenceTermination : null;
    const vehicleYearStartFilter = filterData.vehicleYearStart? filterData.vehicleYearStart : null;
    const vehicleYearFilter = filterData.vehicleYear? filterData.vehicleYear : null;
    const serviceFilter = filterData.services? filterData.services : null;
    const sortFilter = filterData.order;

    if(currentWorkshopId) {
      const clients = await ClientsAPI.getClients(
        currentWorkshopId,
        sortFilter,
        vehicleTypeFilter,
        vehicleBrandFilter,
        vehicleYearFilter,
        vehicleYearStartFilter,
        licenceEndingFilter,
        tagFilter,
        serviceFilter,
        lastServiceFilter,
      );
      setClients(clients.data);
    }
  }

  const updateCustomerList = () => {
    setCurrentWorkshopId(currentWorkshopId);
    getClients();
    //window.location.reload();
  }

  const showModalMessage = (message: string) => {
    setNoticeMessage(message);
    setShowNoticeMessage(true)
  }

  useEffect( () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentWorkshopId(currentWorkshop.id);
  }, []);

  useEffect( () => {
    getClients();
    dispatch(fetchWorkshopTags(currentWorkshopId));
  }, [currentWorkshopId]);

  useEffect(() => {
    getClients();
  }, [filterData]);

  return (
    <div className="page-container" style={{ padding: "2% 5% 5% 5%"}}>
      <Row justify="space-between">
        <p style={{ fontSize: "24px", color: "#001450", fontWeight: 600 }}>
          Lista de clientes
        </p>
        <CkButton onClick={()=> {
          dispatch(resetCustomerFormData());
          setOpenAddClient(true);
          setIsEditingCustomer(false);
        }}
        >
          + Agregar nuevo cliente
        </CkButton>
      </Row>

      <Row>
        <div
          className="gray-background"
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "5px",
            margin: "5px",
            borderRadius: "15px",
            justifyContent: "center",
            width: "265px",
            height: "80px",
          }}
        >
          <div style={{display: "flex", width: "40%",alignItems: "center", justifyContent: "center"}}>
            <p style={{fontSize: "32px", fontWeight: 600, color: "#001450", textAlign: "center", margin: 0}}>
              {clients.length}
            </p>
          </div>
          <div style={{display: "flex", width: "60%",alignItems: "center", justifyContent: "center"}}>
            <p style={{fontSize: "16px", color: "#001450", margin: "0 10px 0 0"}}>
              Clientes en total
            </p>
          </div>
        </div>
        <div
          className="gray-background"
          style={{
            //display: "flex",
            display: "none",
            flexDirection: "row",
            padding: "5px",
            margin: "5px",
            borderRadius: "15px",
            justifyContent: "center",
            width: "265px",
            height: "80px",
          }}
        >
          <div style={{display: "flex", width: "40%",alignItems: "center", justifyContent: "center"}}>
            <p style={{fontSize: "32px", fontWeight: 600, color: "#001450", textAlign: "center", margin: 0}}>
              22
            </p>
          </div>
          <div style={{display: "flex", width: "60%",alignItems: "center", justifyContent: "center"}}>
            <p style={{fontSize: "16px", color: "#001450", margin: "0 10px 0 0"}}>
              Citas completadas este mes
            </p>
          </div>
        </div>
      </Row>

      <ClientList
        currentWorkshopId={currentWorkshopId}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        data={searchTerm.length> 0? addHeaderToData(filteredClients): addHeaderToData(clients)}
        setOpenClientCard={setOpenClientCard}
        setSelectedCustomer={setSelectedCustomer}
        selectedCustomer={selectedCustomer}
        getClients={getClients}
      />

      <AddClientDrawer
        open={openAddClient}
        setOpen={setOpenAddClient}
        currentWorkshopId={currentWorkshopId}
        key="add-client-drawer-001"
        onClose={()=>setOpenAddClient(false)}
        updateCustomerList={updateCustomerList}
        isEditingCustomer={isEditingCustomer}
        setIsEditingCustomer={setIsEditingCustomer}
        showModalMessage={showModalMessage}
      />

      <ClientCardDrawer
        data={selectedCustomer}
        open={openClientCard}
        setOpen={setOpenClientCard}
        key="client-card-drawer-001"
        onClose={() => setOpenClientCard(false)}
        openEdit={() => {
          setOpenAddClient(true);
          setIsEditingCustomer(true);
        }}
      />

      <CkModal
        className="notice-message-modal-customer"
        open={showNoticeMessage}
        onCancel={() => setShowNoticeMessage(false)}
        primaryAction={{
          label: "Aceptar",
          onClick: () => {
            setShowNoticeMessage(false)
          },
        }}
      >
        <p style={{ fontSize: "24px", fontWeight: 600, color: "#2369BD", textAlign: "center"}}>Datos duplicados</p>
        <p style={{ fontSize: "16px"}}>{noticeMessage}</p>
      </CkModal>

    </div>
  )
};

export default memo(MyCustomersPage);
