import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Progress, Popover, Rate, Badge } from "antd";
import {
  DeleteOutlined,
  CopyOutlined,
  MoreOutlined,
  QuestionOutlined,
  PictureOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import GarageIcon from "../../../assets/Images/garage.svg";
import { useIsMobileScreen } from "../../../../src/app/Utilities/WindowSize";

import { setWarningList, setPercentage } from "./warningList";
import { CkButton, CkMessage } from "../../../CkUI";
import "./style.css";
import { gtmSetId } from "../../../app/Utilities";


interface IProps {
  index: number;
  workshop: any;
  workshopTypes: any[];
  sasToken: string;
  goEditWorkshop: Function;
  goEditWorkers: Function;
  removeWorkshop: Function;
  duplicateWorkshop: Function;
  updateNotifications: Function;
  isMec: boolean;
}

interface IPendingItem {
  type: "settings" | "photo" | "info";
  desc: string;
}

const content = (
  index: number,
  removeWorkshop: Function,
  duplicateWorkshop: Function
) => {
  return (
    <div className="ws-popover-content">
      <Button
        className="icon-btn"
        icon={<CopyOutlined />}
        id="ACCTSETUP010"
        onClick={(ev) => {
          gtmSetId(ev.currentTarget)
          duplicateWorkshop(index);
        }}
      >
        Clonar taller
      </Button>
      <Button
        className="icon-btn"
        icon={<DeleteOutlined style={{ color: "red" }} />}
        id="ACCTSETUP011"
        onClick={(ev) => {
          gtmSetId(ev.currentTarget)
          removeWorkshop(index)
        }}
      >
        <span style={{ color: "red" }}>Eliminar taller</span>
      </Button>
    </div>
  );
};

const WorkshopCard: React.FC<IProps> = ({
  index,
  workshop,
  workshopTypes,
  sasToken,
  goEditWorkshop,
  goEditWorkers,
  removeWorkshop,
  duplicateWorkshop,
  updateNotifications,
  isMec,
}) => {
  const isMobile = useIsMobileScreen(992);
  const [tooltipText, setTooltipText] = useState<string>("");
  const [pendingList, setPendingList] = useState<IPendingItem[]>([]);
  const [completionPercent, setCompletionPercent] = useState<number>(0);
  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    if (!!workshop === false) return;
    setPercentage(workshop, setCompletionPercent);
    setWarningList(workshop, setPendingList);
    setIsEnabled(workshop.isEnabled);
  }, [workshop]);

  const getLogo = () => {
    let logoArray: any[] = [];

    if (workshop.workshopPhotos && workshop.workshopPhotos.length > 0) {
      logoArray = workshop.workshopPhotos.filter(
        (e: any) => e.description === "LOGO"
      );
    }

    if (logoArray.length > 0) {
      return `${logoArray[0].photoUrl}?${sasToken}`;
    } else {
      return GarageIcon;
    }
  };

  const isAvailable = () => {
    let available: boolean = true;

    if (workshop) {
      if (workshop.name.length === 0) {
        available = false;
      }
      if (workshop.email.length === 0) {
        available = false;
      }
      if (
        !workshop.workshopPhoneNumber ||
        workshop.workshopPhoneNumber.toString().length === 0
      ) {
        available = false;
      }
      if (
        (!workshop.packages || workshop.packages.length === 0) &&
        (!workshop.serviceTypes || workshop.serviceTypes.length === 0)
      ) {
        available = false;
      }
      if (!workshop.workingHours || workshop.workingHours.length === 0) {
        available = false;
      }

      return available;
    } else {
      return false;
    }
  };

  const pendingConfigurationRender = (isMob: boolean) => {
    return (
      <div
        className={[
          "ws-pending",
          " ",
          ...(isMob === true ? ["for-mob"] : ["for-web"]),
        ].join("")}
      >
        <div className="ws-pending-scroll">
          {pendingList.map((item, index) => (
            <div key={`pl-item-${index}`} className="pending-item">
              {item.type == "settings" && <QuestionOutlined />}
              {item.type == "photo" && <PictureOutlined />}
              {item.type == "info" && <InfoCircleOutlined />}
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "19px",
                }}
              >
                {item.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const optionsRender = (isMob: boolean) => {
    return (
      <div
        className={[
          "ws-options",
          " ",
          ...(isMob === true ? ["for-mob"] : ["for-web"]),
        ].join("")}
      >
        {pendingList.length > 0 && pendingConfigurationRender(true)}

        <div className="ws-status">
          <p>Totalidad del perfil:</p>
          <Progress
            percent={completionPercent}
            trailColor="#D9D9D9"
            strokeColor="var(--primary-color)"
          />
        </div>

        <div className="ws-buttons">
          {!isMec && (
            <CkButton
              style={{ flex: 1 }}
              onClick={() =>
                isAvailable()
                  ? goEditWorkers(index)
                  : CkMessage({
                      type: "warning",
                      text: "Es necesario completar la información requerida para configurar usuarios"
                    })
              }
              variant="secondary"
            >
              Administrar personal
            </CkButton>
          )}
          <CkButton style={{ flex: 1 }} onClick={() => goEditWorkshop(index)} id="ACCTSETUP012" triggerGTMOnClick>
            Editar taller
          </CkButton>
        </div>

        {pendingList.length > 0 && pendingConfigurationRender(false)}
      </div>
    );
  };

  const infoRender = () => {
    return (
      <div className="ws-name">
        <div className="name-more">
          <p className="name">
            {workshop.name}{" "}
          </p>
          {
            (!isMobile && !isEnabled) && (
              <Badge className="disabled-ws-badge" status="default" text="Inhabilitado" />
            )
          }
          <Popover
            zIndex={2}
            overlayInnerStyle={{ borderRadius: "10px" }}
            placement="left"
            content={content(index, removeWorkshop, duplicateWorkshop)}
            trigger="click"
          >
            <Button
              className="icon-btn"
              icon={<MoreOutlined style={{ color: "var(--primary-color)" }} />}
              id="ACCTSETUP008"
              onClick={(ev) => gtmSetId(ev.currentTarget)}
            />
          </Popover>
        </div>
        {
          (isMobile && !isEnabled) && (
            <div>
              <Badge className="disabled-ws-badge" status="default" text="Inhabilitado" />
            </div>
          )
        }

        <div className="ws-pr-rate">
          <div className="carker-rate-container">
            <Rate
              disabled
              allowHalf
              className="carker-rate"
              value={workshop.rating || 0}
            />
          </div>
          {workshop.numberOfFeedbacks <= 0 && (
            <p className="ws-pr-text-rate no-rates">Sin calificaciones</p>
          )}
          {workshop.numberOfFeedbacks >= 1 && (
            <Link
              to={{
                pathname: `/calificaciones/${workshop.id}`,
              }}
              className="--button-blue-link ws-pr-text-rate"
              type="link"
            >
              {workshop.numberOfFeedbacks === 1 && "1 Calificación"}
              {workshop.numberOfFeedbacks > 1 &&
                `${workshop.numberOfFeedbacks} Calificaciones`}
            </Link>
          )}
        </div>
        {optionsRender(false)}
      </div>
    );
  };

  return (
    <div className="workshop-card-container">
      {/*
      <div className="ws-card-header">
        <div className="switch-container">
          <p>Notificaciones:</p>
          <Switch
            defaultChecked={workshop.ownerReceiveNotifications}
            onClick={(value) => {
              updateNotifications(workshop, value);
            }}
            title="Notificaciones"
          />
        </div>

      </div>
      */}
      <div className="ws-card-divisor">
        <div className="ws-info">
          <img
            className={`ws-logo ${
              getLogo() === GarageIcon ? "logo-no-borde" : ""
            }`}
            src={getLogo()}
            style={{ objectFit: "cover" }}
          />
          {infoRender()}
        </div>
        {optionsRender(true)}
      </div>
    </div>
  );
};

export default memo(WorkshopCard);
