interface IPendingItem {
  type: "settings" | "photo" | "info";
  desc: string;
}

export const warningList = {
  name: 'Agrega nombre de tu taller en la sección de "Datos del taller" para tener un perfil más completo.',
  carTypes:
    'Agrega el tipo de vehículo de tu taller en la sección de "Datos del taller" para tener un perfil más completo.',
  about:
    'Agrega "Acerca de" de tu taller en la sección de "Datos del taller" para tener un perfil más completo.',
  workshopPhotos:
    "Los clientes prefieren agendar citas en talleres que incluyan fotos. Actualiza tu taller para tener un perfil más completo.",
  workshopLogo:
    'Sube el logo de tu taller en la sección de "Foto de Perfil" para tener una página de taller más completa y profesional.',
  email:
    'Agrega email de tu taller en la sección de "Datos de contacto" para tener un perfil más completo.',
  workshopPhoneNumber:
    'Agrega el teléfono principal de tu taller en la sección de "Datos de contacto" para tener un perfil más completo.',
  customerPhoneNumber:
    'Agrega el teléfono celular de tu taller en la sección de "Datos de contacto" para tener un perfil más completo.',
  location:
    'Agrega la ubicación de tu taller en la sección de "Ubicación" para tener un perfil más completo.',
  packages:
    'Agrega los paquetes de tu taller en la sección de "Paquetes de mantenimiento disponibles" para tener un perfil más completo.',
  serviceTypes:
    'Agrega los servicios de tu taller en la sección de "Servicios de reparación disponibles" para tener un perfil más completo.',
  workingHours:
    'Agrega las horas de trabajo de tu taller en la sección de "Horario de trabajo" para tener un perfil más completo.',
  additionalInfo:
    'Agrega las características adicionales de tu taller en la sección de "Características adicionales" para tener un perfil más completo.',
};

export const setWarningList = (workshop: any, setPendingList: Function) => {
  let tempPending: IPendingItem[] = [];

  if (workshop) {
    if (workshop.name.length === 0) {
      tempPending.push({
        type: "info",
        desc: warningList.name,
      });
    }
    if (!workshop.carTypes || workshop.carTypes.length === 0) {
      tempPending.push({
        type: "info",
        desc: warningList.carTypes,
      });
    }
    if (!workshop.about || workshop.about.length === 0) {
      tempPending.push({
        type: "info",
        desc: warningList.about,
      });
    }
    if (!workshop.workshopPhotos || workshop.workshopPhotos.length < 3) {
      tempPending.push({
        type: "photo",
        desc: warningList.workshopPhotos,
      });
    }
    if (
      !workshop.workshopPhotos ||
      !workshop.workshopPhotos.find((item: any) => item.description === "LOGO")
    ) {
      tempPending.push({
        type: "settings",
        desc: warningList.workshopLogo,
      });
    }
    if (workshop.email.length === 0) {
      tempPending.push({
        type: "info",
        desc: warningList.email,
      });
    }
    if (
      !workshop.workshopPhoneNumber ||
      workshop.workshopPhoneNumber.toString().length === 0
    ) {
      tempPending.push({
        type: "info",
        desc: warningList.workshopPhoneNumber,
      });
    }
    if (
      !workshop.customerPhoneNumber ||
      workshop.customerPhoneNumber.toString().length == 0
    ) {
      tempPending.push({
        type: "info",
        desc: warningList.customerPhoneNumber,
      });
    }
    if (workshop.location.address.length === 0) {
      tempPending.push({
        type: "info",
        desc: warningList.location,
      });
    }
    if (!workshop.packages || workshop.packages.length == 0) {
      tempPending.push({
        type: "info",
        desc: warningList.packages,
      });
    }
    if (!workshop.serviceTypes || workshop.serviceTypes.length == 0) {
      tempPending.push({
        type: "info",
        desc: warningList.serviceTypes,
      });
    }
    if (!workshop.workingHours || workshop.workingHours.length === 0) {
      tempPending.push({
        type: "info",
        desc: warningList.workingHours,
      });
    }
    if (!workshop.additionalInfo || workshop.additionalInfo.length === 0) {
      tempPending.push({
        type: "info",
        desc: warningList.additionalInfo,
      });
    }

    setPendingList([...tempPending]);
    return;
  } else {
    return;
  }
};

export const setPercentage = (
  workshop: any,
  setCompletionPercent: Function
) => {
  let percentage: number = 0;
  let percentageIncrement: number = 7.692307692;

  if (workshop) {
    if (workshop.name.length > 0) {
      percentage += percentageIncrement;
    }
    if (workshop.carTypes && workshop.carTypes.length > 0) {
      percentage += percentageIncrement;
    }
    if (workshop.about && workshop.about.length > 0) {
      percentage += percentageIncrement;
    }
    if (workshop.workshopPhotos && workshop.workshopPhotos.length >= 3) {
      percentage += percentageIncrement;
    }
    if (
      workshop.workshopPhotos &&
      workshop.workshopPhotos.find((item: any) => item.description === "LOGO")
    ) {
      percentage += percentageIncrement;
    }
    if (workshop.email.length > 0) {
      percentage += percentageIncrement;
    }
    if (
      workshop.workshopPhoneNumber &&
      workshop.workshopPhoneNumber.toString().length > 0
    ) {
      percentage += percentageIncrement;
    }
    if (
      workshop.customerPhoneNumber &&
      workshop.customerPhoneNumber.toString().length > 0
    ) {
      percentage += percentageIncrement;
    }
    if (workshop.location.address.length > 0) {
      percentage += percentageIncrement;
    }
    if (workshop.packages && workshop.packages.length > 0) {
      percentage += percentageIncrement;
    }
    if (workshop.serviceTypes && workshop.serviceTypes.length > 0) {
      percentage += percentageIncrement;
    }
    if (workshop.workingHours && workshop.workingHours.length > 0) {
      percentage += percentageIncrement;
    }
    if (workshop.additionalInfo && workshop.additionalInfo.length > 0) {
      percentage += percentageIncrement;
    }

    let result = Math.trunc(percentage);

    if (result === 99) {
      result += 1;
    }

    setCompletionPercent(result);
    return result;
  } else {
    return 0;
  }
};
